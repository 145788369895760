import './src/styles/global.scss';

import React from 'react';

import { AppWrapper } from './src/components/app-wrapper';

export const wrapPageElement = ({ element }) => (
  <AppWrapper element={element} />
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  // setTimeout(() => {
  if (typeof window !== 'undefined') {
    window.scrollTo(currentPosition[0], currentPosition[1] || 0, 0);
  }
  // }, 50);
  return true;
};
