exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-contact-us-page-contact-us-page-tsx": () => import("./../../../src/templates/contact-us-page/contact-us-page.tsx" /* webpackChunkName: "component---src-templates-contact-us-page-contact-us-page-tsx" */),
  "component---src-templates-editorial-page-editorial-page-tsx": () => import("./../../../src/templates/editorial-page/editorial-page.tsx" /* webpackChunkName: "component---src-templates-editorial-page-editorial-page-tsx" */),
  "component---src-templates-home-page-home-page-tsx": () => import("./../../../src/templates/home-page/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-home-page-tsx" */),
  "component---src-templates-meeting-place-list-page-meeting-place-list-page-tsx": () => import("./../../../src/templates/meeting-place-list-page/meeting-place-list-page.tsx" /* webpackChunkName: "component---src-templates-meeting-place-list-page-meeting-place-list-page-tsx" */),
  "component---src-templates-meeting-place-market-page-meeting-place-market-page-tsx": () => import("./../../../src/templates/meeting-place-market-page/meeting-place-market-page.tsx" /* webpackChunkName: "component---src-templates-meeting-place-market-page-meeting-place-market-page-tsx" */),
  "component---src-templates-meeting-place-page-meeting-place-page-tsx": () => import("./../../../src/templates/meeting-place-page/meeting-place-page.tsx" /* webpackChunkName: "component---src-templates-meeting-place-page-meeting-place-page-tsx" */),
  "component---src-templates-news-item-news-item-tsx": () => import("./../../../src/templates/news-item/news-item.tsx" /* webpackChunkName: "component---src-templates-news-item-news-item-tsx" */),
  "component---src-templates-news-list-news-list-tsx": () => import("./../../../src/templates/news-list/news-list.tsx" /* webpackChunkName: "component---src-templates-news-list-news-list-tsx" */),
  "component---src-templates-not-found-tsx": () => import("./../../../src/templates/not-found.tsx" /* webpackChunkName: "component---src-templates-not-found-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/footer/footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */)
}

